/* REMOVE BLUE HIGHLIGHT ON ANDROID */
* {
    -webkit-tap-highlight-color: transparent;
}
*:focus {
    outline: none !important;
}

@-webkit-keyframes expand {
  0% {
    -webkit-transform: scaleX(0%);
  }
  80% {
    -webkit-transform: scaleX(110%);
  }
  100% {
    -webkit-transform: scaleX(100%);
  }
}

@keyframes expand {
  0% {
    transform: scaleX(0%);
  }
  80% {
    transform: scaleX(110%);
  }
  100% {
    transform: scaleX(100%);
  }
}

#underAnimate {
  -webkit-animation-name: expand;
  -webkit-animation-duration: 0.2s;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-delay: 0s;
  animation-name: expand;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  
}

@keyframes slideFromTop {

  0% {
    transform: translatex(calc(100vw));
  }
  100% {
    transform: translateY(0);
  }

}

#slideFromTopAnim {
  animation: 0.25s ease-out 0s slideFromTop;
}